import { Close } from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Button,
  CircularProgress,
  debounce,
  Dialog,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Skeleton,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextFieldProps,
  Toolbar,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useField, useFormikContext } from "formik";
import { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from "react";
import { NumericFormat, useNumericFormat } from "react-number-format";
import {
  LoanDataPayload,
  LoanDataResponse,
  PrequalificationFields,
  PrequalificationOptionsResponse,
} from "Services/api/request/interfaces";
import { calculatePrequalification } from "Services/api/request/request";
import FormikCheckbox from "Shared/FormikCheckbox/FormikCheckbox";
import { FormikMoney, numericConfig } from "Shared/FormikMoney/FormikMoney";
import FormikSelect from "Shared/FormikSelect/FormikSelect";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { requiredNumberValidation, requiredValidation } from "Utils/validations";
import * as Yup from "yup";
import { debounceTime } from "./debounceTime";

export type LoanRequestSteps = "prequalification" | "data" | "verification";
interface PrequalificationProps {
  options: PrequalificationOptionsResponse["data"];
  isRegistration?: boolean;
  isFirstCredit?: boolean;
}

export function PrequalificationForm({
  options: { numberOfDependents, typeOfHousing, levelOfEducation },
  isRegistration = false,
  isFirstCredit = false,
}: PrequalificationProps) {
  const [calculating, setCalculating] = useState(false);
  const max = new Date().toISOString().split("T")[0];
  const clientWithLoanHistory = !isFirstCredit && !isRegistration;
  const formDisabled = calculating || clientWithLoanHistory;

  return (
    <Fragment>
      {clientWithLoanHistory && (
        <Alert severity="info" color="success" variant="outlined">
          Favor verificar los siguientes datos. En caso de que alguno este incorrecto o desactualizado favor de
          contactarnos.
        </Alert>
      )}
      <FormikMoney
        name="salary"
        id="salary"
        fullWidth
        variant="outlined"
        label={"Salario mensual:"}
        disabled={formDisabled}
      />
      <FormikTextField
        name="dateOfHire"
        id="dateOfHire"
        fullWidth
        label="Fecha de entrada:"
        variant="outlined"
        type="date"
        inputProps={{
          max,
        }}
        required
        disabled={formDisabled}
      />
      <FormikSelect
        id="numberOfDependents"
        name="numberOfDependents"
        label="Cantidad de dependientes:"
        required
        disabled={formDisabled}
      >
        {numberOfDependents.map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </FormikSelect>
      <FormikSelect id="typeOfHousing" name="typeOfHousing" label="Tipo vivienda:" required disabled={formDisabled}>
        {typeOfHousing.map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </FormikSelect>
      <FormikSelect
        id="levelOfEducation"
        name="levelOfEducation"
        label="Nivel de educación:"
        required
        disabled={formDisabled}
      >
        {levelOfEducation.map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </FormikSelect>
      <PrequalificationAmount calculating={calculating} setCalculating={setCalculating} />
    </Fragment>
  );
}

function PrequalificationAmount({
  calculating,
  setCalculating,
}: {
  calculating: boolean;
  setCalculating: Dispatch<SetStateAction<boolean>>;
}) {
  const { values } = useFormikContext<PrequalificationFields>();
  const { salary, dateOfHire, numberOfDependents, typeOfHousing, levelOfEducation } = values;
  const [amount, setAmount] = useState(0);
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig, fixedDecimalScale: true });

  const { mutateAsync: calculate } = useMutation({
    mutationFn: async () => {
      setCalculating(true);
      if (salary && dateOfHire && numberOfDependents && typeOfHousing && levelOfEducation) {
        const result = await calculatePrequalification(values);
        setAmount(result.data);
      } else setAmount(0);
      setCalculating(false);
    },
    onError: () => {
      setCalculating(false);
    },
  });

  const fetch = useMemo(
    () =>
      debounce(() => {
        void calculate();
      }, debounceTime),
    [calculate]
  );

  useEffect(() => {
    fetch();
  }, [salary, dateOfHire, numberOfDependents, typeOfHousing, levelOfEducation, calculate, fetch]);

  return calculating ? (
    <Stack spacing={1} width="100%">
      <label>{"Monto de precalificación:"}</label>
      <CircularProgress />
    </Stack>
  ) : (
    <NumericFormat
      name="prequalificationAmount"
      id="prequalificationAmount"
      fullWidth
      label="Monto de precalificación:"
      variant="outlined"
      value={format(amount.toString())}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      customInput={FormikTextField}
      disabled
      {...numericConfig}
    />
  );
}

export function PrequalificationSkeleton() {
  return (
    <Stack width={"100%"} spacing={2}>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>

      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Skeleton variant="rectangular" width={"100%"} height={37} />
    </Stack>
  );
}

export function prequalificationSchema() {
  const max = new Date().toISOString().split("T")[0];
  return Yup.object({
    salary: requiredNumberValidation.nullable().moreThan(0, "Salario tiene que ser mayor que cero"),
    dateOfHire: Yup.date().required("Requerido").max(max, `Fecha debe ser menor que ${max}`),
    numberOfDependents: requiredValidation,
    typeOfHousing: requiredValidation,
    levelOfEducation: requiredValidation,
  });
}

const [minTerm, maxTerm] = [6, 60];
const [minPaymentDay, maxPaymentDay] = [1, 31];

interface LoanDataFormProps {
  prequalification: number;
  savingsWarrantyRate: number;
  capturePaymentDay: boolean;
  savingsBalance?: number;
  loanBalance?: number;
}

export function LoanDataForm({
  prequalification,
  savingsWarrantyRate,
  capturePaymentDay,
  savingsBalance = 0,
  loanBalance = 0,
}: LoanDataFormProps) {
  return (
    <Fragment>
      <LoanAmount label="Monto solicitud" required prequalification={prequalification} />
      <Term min={minTerm} max={maxTerm} />
      {capturePaymentDay && (
        <FormikTextField
          name="paymentDay"
          id="paymentDay"
          label="Dia de pago"
          variant="outlined"
          type="number"
          inputProps={{
            min: minPaymentDay,
            max: maxPaymentDay,
          }}
          required
        />
      )}
      <IncludeSavingsWarranty
        savingsWarrantyRate={savingsWarrantyRate}
        savingsBalance={savingsBalance}
        loanBalance={loanBalance}
      />
    </Fragment>
  );
}

function IncludeSavingsWarranty({
  savingsWarrantyRate,
  savingsBalance,
  loanBalance,
}: {
  savingsWarrantyRate: number;
  savingsBalance: number;
  loanBalance: number;
}) {
  const [{ value }] = useField<LoanDataPayload["amount"]>("amount");
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);
  let savingsWarranty = ((value || 0) + loanBalance) * savingsWarrantyRate - savingsBalance;
  savingsWarranty = savingsWarranty > 0 ? savingsWarranty : 0;
  const [openHelp, setOpenHelp] = useState(false);

  if (!savingsWarranty) return null;

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" flexWrap="wrap" spacing={1}>
        <label htmlFor={"includeSavingsWarranty"}>Garantía de ahorro</label>
        <Button variant="text" onClick={() => setOpenHelp(!openHelp)} sx={{ p: 0 }}>
          {openHelp ? "Cerrar ayuda" : "¿Qué es esto?"}
        </Button>
      </Stack>

      {openHelp && (
        <Alert severity="info" color="success" variant="outlined">
          Para optar a un crédito, debes contar con ahorros equivalentes al menos al 5% del monto solicitado.
        </Alert>
      )}
      <FormikCheckbox
        id="includeSavingsWarranty"
        name="includeSavingsWarranty"
        label={`Financiar el porcentaje correspondiente a la garantia de ahorro (RD$${format(
          savingsWarranty.toString()
        )})`}
      />
    </Stack>
  );
}

function LoanAmount({ label, prequalification }: TextFieldProps & { prequalification: number }) {
  const [{ value }, { error }, { setValue }] = useField<LoanDataPayload["amount"]>("amount");
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);
  return (
    <NumericFormat
      name="amount"
      id="amount"
      fullWidth
      label={label}
      required
      variant="outlined"
      value={value ? format(value.toString()) : ""}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      customInput={FormikTextField}
      {...numericConfig}
      onValueChange={({ floatValue }) => {
        setValue(floatValue ? floatValue : null);
      }}
      helperText={error ? error : `Monto maximo: RD$${format(prequalification.toString())}`}
      error={Boolean(error)}
    />
  );
}

function Term({ min, max }: { min: number; max: number }) {
  const [{ value }, { error }, { setValue }] = useField<number>("term");

  return (
    <Stack spacing={1} width="100%">
      <label htmlFor={"term"}>Plazo</label>
      <Typography variant="subtitle1">{value} meses</Typography>
      <Slider
        id="term"
        name="term"
        aria-label="Plazo"
        valueLabelDisplay="auto"
        value={value}
        step={6}
        marks
        min={min}
        max={max}
        onChange={(_, value) => {
          if (typeof value === "number") setValue(value);
        }}
      />
      <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
    </Stack>
  );
}

export function useLoanDataFormSchema(prequalification: number, institutionIsAffiliated: boolean) {
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);

  const schema: { [k: string]: Yup.AnySchema } = {
    amount: requiredNumberValidation
      .nullable()
      .moreThan(0, "Monto tiene que ser mayor que cero")
      .max(prequalification, `Monto no puede exceder RD$${format(prequalification.toString())}`),
    term: Yup.number()
      .required("Requerido")
      .integer("Debe ser un entero")
      .positive("Debe ser un entero positivo")
      .min(minTerm, `No menos de ${minTerm} meses`)
      .max(maxTerm, `No mas de ${maxTerm} meses`),
    includeSavingsWarranty: Yup.boolean(),
  };

  if (!institutionIsAffiliated)
    schema["paymentDay"] = Yup.number()
      .required("Requerido")
      .integer("Debe ser un entero")
      .positive("Debe ser un entero positivo")
      .min(minPaymentDay, `No menos de ${minPaymentDay}`)
      .max(maxPaymentDay, `No mas de ${maxPaymentDay}`);

  const validationSchema = Yup.object(schema);

  return validationSchema;
}

interface VerificaciónProps {
  loanData: LoanDataResponse;
}

export function Verification({
  loanData: {
    apr,
    amount,
    amountAfterCharges,
    term,
    interestRate,
    payment,
    administrativeCharges,
    includeSavingsWarranty,
    savingsWarranty,
    includeAffiliationFees,
    affiliationFees,
    paymentDay,
    secondPaymentDay,
    paymentPlan,
  },
}: VerificaciónProps) {
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig, fixedDecimalScale: true });
  const [openPaymentPlan, setOpenPaymentPlan] = useState(false);
  return (
    <Stack spacing={2} width="100%">
      <Table width="100%">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Stack width="100%" alignItems="center">
                <Typography fontWeight="bold">Monto a financiar:</Typography>
                <Typography fontWeight="bold" fontSize="1.5rem">
                  RD${format(amountAfterCharges.toString())}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>Tipo:</strong>
            </TableCell>
            <TableCell>Préstamo Regular</TableCell>
          </TableRow>
          {includeSavingsWarranty && (
            <TableRow>
              <TableCell>
                <strong>Garantia de Ahorro (5%):</strong>
              </TableCell>
              <TableCell>RD${format(savingsWarranty.toString())}</TableCell>
            </TableRow>
          )}
          {includeAffiliationFees && (
            <TableRow>
              <TableCell>
                <strong>Monto de afiliación:</strong>
              </TableCell>
              <TableCell>RD${format(affiliationFees.toString())}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <strong>Gastos administrativos:</strong>
            </TableCell>
            <TableCell>RD${format(administrativeCharges.toString())}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Monto a desembolsar:</strong>
            </TableCell>
            <TableCell>RD${format(amount.toString())}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Plazo:</strong>
            </TableCell>
            <TableCell>{term} meses</TableCell>
          </TableRow>
          {secondPaymentDay > 0 ? (
            <TableRow>
              <TableCell>
                <strong>Dias de pago:</strong>
              </TableCell>
              <TableCell>
                {paymentDay} y {secondPaymentDay}
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell>
                <strong>Dia de pago:</strong>
              </TableCell>
              <TableCell>{paymentDay}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <strong>Tasa de interes:</strong>
            </TableCell>
            <TableCell>{interestRate}% mensual</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Tasa anual equivalente:</strong>
            </TableCell>
            <TableCell>{apr}%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Cuota:</strong>
            </TableCell>
            <TableCell>RD${format(payment)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Button variant="outlined" onClick={() => setOpenPaymentPlan(true)}>
        Ver plan de pago
      </Button>
      <PaymentPlanDialog open={openPaymentPlan} setOpen={setOpenPaymentPlan} paymentPlan={paymentPlan} />
    </Stack>
  );
}

interface PaymentPlanDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  paymentPlan: LoanDataResponse["paymentPlan"];
}

function PaymentPlanDialog({ open, setOpen, paymentPlan }: PaymentPlanDialogProps) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Plan de pago
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            CERRAR
          </Button>
        </Toolbar>
      </AppBar>
      <Table width="100%" sx={{ mt: { xs: "64px", sm: "72px" } }}>
        <TableHead>
          <TableRow>
            <TableCell>Periodo</TableCell>
            <TableCell sx={{ textWrap: "nowrap" }}>Fecha de pago</TableCell>
            <TableCell sx={{ textWrap: "nowrap" }}>Balance inicial</TableCell>
            <TableCell>Cuota</TableCell>
            <TableCell>Capital</TableCell>
            <TableCell>Interés</TableCell>
            <TableCell>Otro</TableCell>
            <TableCell>Seguro</TableCell>
            <TableCell>Ahorro</TableCell>
            <TableCell>Aportaciones</TableCell>
            <TableCell sx={{ textWrap: "nowrap" }}>Balance final</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentPlan.map((payment) => {
            const [
              period,
              paymentDay,
              principal,
              monthlyPayment,
              capital,
              interest,
              otherInsurance,
              insurance,
              savings,
              contributions,
              final,
            ] = payment;

            if (period === 0) return null;

            return (
              <TableRow key={period}>
                <TableCell>{period}</TableCell>
                <TableCell>{paymentDay}</TableCell>
                <TableCell>{principal}</TableCell>
                <TableCell>{monthlyPayment}</TableCell>
                <TableCell>{capital}</TableCell>
                <TableCell>{interest}</TableCell>
                <TableCell>{otherInsurance}</TableCell>
                <TableCell>{insurance}</TableCell>
                <TableCell>{savings}</TableCell>
                <TableCell>{contributions}</TableCell>
                <TableCell>{final}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Dialog>
  );
}
