import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Alert, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  LoanDataResponse,
  LoanRequestPayload,
  UpdatePrequalification,
  UserPrequalificationValues,
} from "Services/api/request/interfaces";
import {
  createLoanRequest,
  getPaymentPlan,
  getPrequalificationOptions,
  getProductRequests,
  getUserPrequalificationValues,
  updatePrequalification,
} from "Services/api/request/request";
import FormikForm from "Shared/FormikForm/FormikForm";
import { FormikImageField } from "Shared/FormikImageField/FormikImageField";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import {
  LoanDataForm,
  LoanRequestSteps,
  PrequalificationForm,
  prequalificationSchema,
  PrequalificationSkeleton,
  useLoanDataFormSchema,
  Verification,
} from "Shared/LoanRequestForm/LoanRequestForm";
import { Query } from "Shared/Query/Query";
import { SavingsAccountSelect } from "Shared/SavingAccountSelect/SavingAccountSelect";
import { SectionTitle } from "Shared/styled";
import { requiredNumberValidation } from "Utils/validations";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";

export function LoanRequest() {
  const [step, setStep] = useState<LoanRequestSteps>("prequalification");
  const [prequalificationValues, setPrequalificationValues] = useState<UserPrequalificationValues>({
    isFirstCredit: false,
    salary: null,
    dateOfHire: "",
    numberOfDependents: "",
    typeOfHousing: "",
    levelOfEducation: "",
    affiliatedInstitution: false,
  });
  const [loanFormConfig, setLoanFormConfig] = useState<UpdatePrequalification>({
    prequalification: 0,
    savingsWarrantyRate: 0,
    savingsBalance: 0,
    loanBalance: 0,
    capturePaymentDay: true,
    paymentDay: 28,
  });
  const [loanData, setLoanData] = useState<LoanDataResponse>({
    apr: 0,
    amount: 0,
    administrativeCharges: 0,
    amountAfterCharges: 0,
    includeSavingsWarranty: false,
    savingsWarranty: 0,
    includeAffiliationFees: false,
    affiliationFees: 0,
    term: 6,
    interestRate: 0,
    payment: "0",
    paymentDay: 28,
    secondPaymentDay: 0,
    paymentPlan: [],
  });
  const [formData, setFormData] = useState<LoanRequestPayload>({
    amount: null,
    term: 6,
    paymentDay: 28,
    includeSavingsWarranty: false,
    includeAffiliationFees: false,
    account: "",
    addressCert: null,
  });

  switch (step) {
    case "prequalification":
      return (
        <Stack>
          <SectionTitle variant="h1">Datos precalificación</SectionTitle>
          <PrequalificationStepForm
            onSubmit={(data, values) => {
              setStep("data");
              setLoanFormConfig(data);
              setPrequalificationValues(values);
              setFormData({ ...formData, paymentDay: data["paymentDay"] });
            }}
          />
        </Stack>
      );

    case "data":
      return (
        <Stack>
          <SectionTitle variant="h1">Solicitud de financiamiento</SectionTitle>
          <Data
            loanFormConfig={loanFormConfig}
            prequalificationValues={prequalificationValues}
            formData={formData}
            onBack={() => {
              setStep("prequalification");
            }}
            onSubmit={(values, data) => {
              setFormData(values);
              setLoanData(data);
              setStep("verification");
            }}
          />
        </Stack>
      );

    case "verification":
      return <VerificationStep formData={formData} loanData={loanData} onBack={() => setStep("data")} />;
  }
}

function PrequalificationStepForm({
  onSubmit,
}: {
  onSubmit: (data: UpdatePrequalification, values: UserPrequalificationValues) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const resultOptions = useQuery({
    queryKey: [getPrequalificationOptions.name],
    queryFn: async () => {
      try {
        const { data } = await getPrequalificationOptions();
        return data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const resultValues = useQuery({
    queryKey: [getUserPrequalificationValues.name],
    queryFn: async () => {
      try {
        const { data } = await getUserPrequalificationValues();
        return data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <Query
      result={resultOptions}
      OnLoading={() => <PrequalificationSkeleton />}
      onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
      onSuccess={(options) => (
        <Query
          result={resultValues}
          OnLoading={() => <PrequalificationSkeleton />}
          onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
          onSuccess={(values) => (
            <Formik
              initialValues={values}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const { data } = await updatePrequalification(values);
                  onSubmit(data, values);
                } catch (error) {
                  enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
                }

                setSubmitting(false);
              }}
              validationSchema={prequalificationSchema()}
            >
              <FormikForm width="100%">
                <PrequalificationForm options={options} isFirstCredit={values.isFirstCredit} />
                <FormikSubmitButton fullWidth variant="contained">
                  Siguiente
                </FormikSubmitButton>
              </FormikForm>
            </Formik>
          )}
        />
      )}
    />
  );
}

interface DataProps {
  loanFormConfig: UpdatePrequalification;
  prequalificationValues: UserPrequalificationValues;
  formData: LoanRequestPayload;
  onBack: () => void;
  onSubmit: (values: LoanRequestPayload, data: LoanDataResponse) => void;
}

function Data({
  loanFormConfig,
  prequalificationValues: { isFirstCredit, affiliatedInstitution },
  formData,
  onBack,
  onSubmit,
}: DataProps) {
  const { prequalification, savingsWarrantyRate, savingsBalance, loanBalance, capturePaymentDay } = loanFormConfig;
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useLoanDataFormSchema(prequalification, affiliatedInstitution);
  const requiredCert = isFirstCredit && !affiliatedInstitution;
  const schema = validationSchema.concat(
    Yup.object({ includeAffiliationFees: Yup.boolean(), account: requiredNumberValidation })
  );

  return (
    <Formik
      initialValues={formData}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const { data } = await getPaymentPlan(values);
          onSubmit(values, data);
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
          console.error(error);
        }

        setSubmitting(false);
      }}
      validationSchema={
        requiredCert ? schema.concat(Yup.object({ addressCert: Yup.mixed().required("Archivo requerido") })) : schema
      }
    >
      <FormikForm width="100%">
        <LoanDataForm
          prequalification={prequalification}
          savingsWarrantyRate={savingsWarrantyRate}
          savingsBalance={savingsBalance}
          loanBalance={loanBalance}
          capturePaymentDay={capturePaymentDay}
        />
        <SavingsAccountSelect name="account" label="Cuenta a desembolsar" include={["in_sight"]} />
        {requiredCert && (
          <FormikImageField
            name="addressCert"
            label="Certificación de dirección"
            help={
              <Alert severity="info" color="success" variant="outlined">
                Puede ser una factura de servicio eléctrico o telefónico a nombre de la persona que solicita el
                préstamo, y debe mostrar la dirección actual de dicha persona.
              </Alert>
            }
          />
        )}

        <Stack direction="row" width="100%" spacing={2}>
          <Button fullWidth startIcon={<ChevronLeft />} sx={{ justifyContent: "flex-start" }} onClick={onBack}>
            ATRAS
          </Button>
          <FormikSubmitButton
            variant="contained"
            fullWidth
            endIcon={<ChevronRight />}
            sx={{ justifyContent: "flex-end" }}
          >
            SIGUIENTE
          </FormikSubmitButton>
        </Stack>
      </FormikForm>
    </Formik>
  );
}

interface VerificationStepProps {
  formData: LoanRequestPayload;
  loanData: LoanDataResponse;
  onBack: () => void;
}

function VerificationStep({ formData, loanData, onBack }: VerificationStepProps) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const result = useMutation({
    mutationFn: async () => {
      const requestData = new FormData();

      for (const key in formData) {
        const el = formData[key as keyof LoanRequestPayload];
        if (el) requestData.append(key, typeof el === "number" || typeof el === "boolean" ? el.toString() : el);
      }
      const { data } = await createLoanRequest(requestData);
      enqueueSnackbar("Solicitud creada exitosamente", { variant: "success" });

      void queryClient.invalidateQueries({
        queryKey: [getProductRequests.name],
        exact: true,
      });

      if (data.signatureRequired)
        navigate({
          pathname: "/solicitudes/firma/",
          search: createSearchParams({
            id: data.uniqueId,
            type: data.type,
          }).toString(),
        });
      else navigate("/solicitudes/estados/");
    },
    onError: (error) => {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    },
  });

  const { mutateAsync: createRequest, isPending } = result;

  if (isPending) return <CreatingRequest />;

  return (
    <Stack>
      <SectionTitle variant="h1">Verifica los datos de tu solicitud</SectionTitle>
      <Stack spacing={2}>
        <Verification loanData={loanData} />
        <Stack direction="row" width="100%" spacing={2}>
          <Button fullWidth startIcon={<ChevronLeft />} sx={{ justifyContent: "flex-start" }} onClick={onBack}>
            ATRAS
          </Button>
          <Button
            fullWidth
            variant="contained"
            endIcon={<ChevronRight />}
            sx={{ justifyContent: "flex-end" }}
            onClick={() => {
              void createRequest();
            }}
          >
            SIGUIENTE
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

function CreatingRequest() {
  return (
    <Stack
      sx={{ width: "100%", height: "calc(100vh - 80px - 24px - 56px)", alignItems: "center", justifyContent: "center" }}
      spacing={2}
    >
      <CircularProgress size={"7.5rem"} thickness={4} />
      <Typography sx={{ fontSize: "1.5rem", fontWeight: 400 }}>Creando solicitud de préstamo...</Typography>
    </Stack>
  );
}
